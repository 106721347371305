var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var ffa,gfa,hfa,ifa,jfa,kfa,lfa,TG,mfa,nfa,ofa,pfa,qfa,rfa,sfa,tfa,VG,ufa;$CLJS.OG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);ffa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.PG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);gfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
hfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.QG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.RG=new $CLJS.M(null,"column-name","column-name",551523580);ifa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.SG=new $CLJS.M(null,"display-info","display-info",-816930907);jfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
kfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);lfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);TG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);mfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);nfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);ofa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
pfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);qfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);rfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);sfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.UG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);tfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
VG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.WG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);ufa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.EF($CLJS.Wx,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)],null)],null)]));$CLJS.EF($CLJS.oG,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)],null)],null)]));
$CLJS.CF($CLJS.hG,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.CF($CLJS.TF,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)]));$CLJS.CF($CLJS.wG,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));
$CLJS.CF($CLJS.jk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.vF($CLJS.jk,$CLJS.hF);$CLJS.CF($CLJS.BG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.vF($CLJS.BG,$CLJS.hF);$CLJS.CF($CLJS.Zn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.vF($CLJS.Zn,$CLJS.hF);
$CLJS.X(TG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.vt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.CF($CLJS.ZF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TG],null)]));$CLJS.vF($CLJS.ZF,$CLJS.hF);
$CLJS.CF($CLJS.uG,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.CF($CLJS.CG,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.CF($CLJS.AG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));
$CLJS.CF($CLJS.JG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.vF($CLJS.AG,$CLJS.hF);$CLJS.vF($CLJS.JG,$CLJS.hF);$CLJS.CF($CLJS.HG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.vF($CLJS.HG,$CLJS.hF);
$CLJS.CF($CLJS.UF,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.X(VG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.xs,tfa,rfa,kfa,gfa,ffa,jfa,lfa,mfa,nfa,qfa,ofa,ufa,sfa,hfa,pfa,$CLJS.Dl],null));$CLJS.X($CLJS.PG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VG],null)],null));
$CLJS.XG=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.gG,$CLJS.Wx,$CLJS.QG,!1,$CLJS.UG,$CLJS.IF,$CLJS.SG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Count of rows"),$CLJS.RG,$CLJS.PE("Count"),$CLJS.Pt,$CLJS.PE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.AG,$CLJS.OG,$CLJS.fk,$CLJS.QG,!0,$CLJS.UG,$CLJS.IF,$CLJS.SG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Sum of ..."),$CLJS.RG,$CLJS.PE("Sum"),$CLJS.Pt,$CLJS.PE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.hG,$CLJS.OG,$CLJS.fk,$CLJS.QG,!0,$CLJS.UG,$CLJS.IF,$CLJS.SG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Average of ..."),$CLJS.RG,$CLJS.PE("Average"),$CLJS.Pt,$CLJS.PE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.BG,$CLJS.OG,$CLJS.fk,$CLJS.QG,!0,$CLJS.UG,$CLJS.jG,$CLJS.SG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Median of ..."),$CLJS.RG,$CLJS.PE("Median"),$CLJS.Pt,$CLJS.PE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.TF,$CLJS.OG,$CLJS.li,$CLJS.QG,!0,$CLJS.UG,$CLJS.IF,$CLJS.SG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Number of distinct values of ..."),$CLJS.RG,$CLJS.PE("Distinct values"),$CLJS.Pt,$CLJS.PE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.JG,$CLJS.OG,$CLJS.fk,$CLJS.QG,!0,$CLJS.UG,$CLJS.IF,$CLJS.SG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Cumulative sum of ..."),
$CLJS.RG,$CLJS.PE("Sum"),$CLJS.Pt,$CLJS.PE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.gG,$CLJS.oG,$CLJS.QG,!1,$CLJS.UG,$CLJS.IF,$CLJS.SG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Cumulative count of rows"),$CLJS.RG,$CLJS.PE("Count"),$CLJS.Pt,$CLJS.PE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.CG,$CLJS.OG,$CLJS.fk,$CLJS.QG,
!0,$CLJS.UG,$CLJS.xG,$CLJS.SG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Standard deviation of ..."),$CLJS.RG,$CLJS.PE("SD"),$CLJS.Pt,$CLJS.PE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.Zn,$CLJS.OG,$CLJS.Zi,$CLJS.QG,!0,$CLJS.UG,$CLJS.IF,$CLJS.SG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Minimum of ..."),$CLJS.RG,$CLJS.PE("Min"),$CLJS.Pt,$CLJS.PE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.jk,$CLJS.OG,$CLJS.Zi,$CLJS.QG,!0,$CLJS.UG,$CLJS.IF,$CLJS.SG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Maximum of ..."),$CLJS.RG,$CLJS.PE("Max"),$CLJS.Pt,$CLJS.PE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(ifa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.WG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.us],null),$CLJS.hf.h($CLJS.gG),$CLJS.XG)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.Yi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QG,$CLJS.Ms],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.UG,$CLJS.Yi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SG,$CLJS.md],null)],null));