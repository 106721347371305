var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var gu,yba,zba,ju,Aba,mu,nu;
$CLJS.fu=function(a,b,c){return function(){function d(t,u,v){return a.N?a.N(b,c,t,u,v):a.call(null,b,c,t,u,v)}function e(t,u){return a.v?a.v(b,c,t,u):a.call(null,b,c,t,u)}function f(t){return a.j?a.j(b,c,t):a.call(null,b,c,t)}function k(){return a.g?a.g(b,c):a.call(null,b,c)}var l=null,m=function(){function t(v,x,z,C){var G=null;if(3<arguments.length){G=0;for(var K=Array(arguments.length-3);G<K.length;)K[G]=arguments[G+3],++G;G=new $CLJS.w(K,0,null)}return u.call(this,v,x,z,G)}function u(v,x,z,C){return $CLJS.Se.l(a,
b,c,v,x,$CLJS.H([z,C]))}t.A=3;t.B=function(v){var x=$CLJS.A(v);v=$CLJS.B(v);var z=$CLJS.A(v);v=$CLJS.B(v);var C=$CLJS.A(v);v=$CLJS.Lc(v);return u(x,z,C,v)};t.l=u;return t}();l=function(t,u,v,x){switch(arguments.length){case 0:return k.call(this);case 1:return f.call(this,t);case 2:return e.call(this,t,u);case 3:return d.call(this,t,u,v);default:var z=null;if(3<arguments.length){z=0;for(var C=Array(arguments.length-3);z<C.length;)C[z]=arguments[z+3],++z;z=new $CLJS.w(C,0,null)}return m.l(t,u,v,z)}throw Error("Invalid arity: "+
arguments.length);};l.A=3;l.B=m.B;l.o=k;l.h=f;l.g=e;l.j=d;l.l=m.l;return l}()};gu=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):new $CLJS.P(null,3,5,$CLJS.Q,[a,b,null],null)};yba=function(a){return $CLJS.y(a)?$CLJS.fb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return $CLJS.R.j(b,d,c)},$CLJS.Df($CLJS.df($CLJS.Se.g($CLJS.Lk,$CLJS.Xg(a)),null)),a):null};
zba=function(a,b,c){var d=$CLJS.J.g(a,c),e=$CLJS.J.g(b,c),f=$CLJS.hu(d,e),k=$CLJS.I(f,0,null),l=$CLJS.I(f,1,null);f=$CLJS.I(f,2,null);a=$CLJS.Hd(a,c);b=$CLJS.Hd(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.P(null,3,5,$CLJS.Q,[!a||null==k&&d?null:$CLJS.Ee([c,k]),!b||null==l&&d?null:$CLJS.Ee([c,l]),d?$CLJS.Ee([c,f]):null],null)};
ju=function(a,b){var c=$CLJS.hf.g,d=iu.j,e=$CLJS.zd(a)?a:$CLJS.Df(a),f=$CLJS.zd(b)?b:$CLJS.Df(b);a=$CLJS.D(a);b=$CLJS.D(b);return $CLJS.Df(c.call($CLJS.hf,yba,d.call(iu,e,f,$CLJS.lh(0,a>b?a:b))))};Aba=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fe($CLJS.ku.g(a,b)),$CLJS.Fe($CLJS.ku.g(b,a)),$CLJS.Fe($CLJS.lu.g(a,b))],null)};
mu=function(a){if(null!=a&&null!=a.gh)a=a.gh(a);else{var b=mu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=mu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("EqualityPartition.equality-partition",a);}return a};nu=function(a,b){if(null!=a&&null!=a.fh)a=a.fh(a,b);else{var c=nu[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=nu._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("Diff.diff-similar",a);}return a};
$CLJS.hu=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):$CLJS.E.g(mu(a),mu(b))?nu(a,b):gu(a,b)};$CLJS.lu=function lu(a){switch(arguments.length){case 1:return lu.h(arguments[0]);case 2:return lu.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return lu.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.lu.h=function(a){return a};
$CLJS.lu.g=function(a,b){for(;;)if($CLJS.D(b)<$CLJS.D(a)){var c=a;a=b;b=c}else return $CLJS.fb(function(d,e){return function(f,k){return $CLJS.Hd(e,k)?f:$CLJS.Ik.g(f,k)}}(a,b),a,a)};$CLJS.lu.l=function(a,b,c){a=$CLJS.bu(function(d){return-$CLJS.D(d)},$CLJS.be.l(c,b,$CLJS.H([a])));return $CLJS.fb($CLJS.lu,$CLJS.A(a),$CLJS.Lc(a))};$CLJS.lu.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.lu.A=2;
$CLJS.ku=function ku(a){switch(arguments.length){case 1:return ku.h(arguments[0]);case 2:return ku.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return ku.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.ku.h=function(a){return a};$CLJS.ku.g=function(a,b){return $CLJS.D(a)<$CLJS.D(b)?$CLJS.fb(function(c,d){return $CLJS.Hd(b,d)?$CLJS.Ik.g(c,d):c},a,a):$CLJS.fb($CLJS.Ik,a,b)};
$CLJS.ku.l=function(a,b,c){return $CLJS.fb($CLJS.ku,a,$CLJS.be.g(c,b))};$CLJS.ku.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.ku.A=2;var ou=new $CLJS.M(null,"atom","atom",-397043653);var iu=function iu(a){switch(arguments.length){case 2:return iu.g(arguments[0],arguments[1]);case 3:return iu.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};iu.g=function(a,b){return iu.j(a,b,$CLJS.eu.g($CLJS.Xg(a),$CLJS.Xg(b)))};iu.j=function(a,b,c){return $CLJS.fb(function(d,e){return $CLJS.mh($CLJS.hf.j($CLJS.gl,d,e))},new $CLJS.P(null,3,5,$CLJS.Q,[null,null,null],null),$CLJS.hf.g($CLJS.fu(zba,a,b),c))};iu.A=3;
mu["null"]=function(){return ou};mu.string=function(){return ou};mu.number=function(){return ou};mu.array=function(){return $CLJS.Aj};mu["function"]=function(){return ou};mu["boolean"]=function(){return ou};
mu._=function(a){return(null!=a?a.C&1024||$CLJS.Bc===a.Ug||(a.C?0:$CLJS.$a($CLJS.Cb,a)):$CLJS.$a($CLJS.Cb,a))?$CLJS.ik:(null!=a?a.C&4096||$CLJS.Bc===a.Yg||(a.C?0:$CLJS.$a($CLJS.Gb,a)):$CLJS.$a($CLJS.Gb,a))?$CLJS.aj:(null!=a?a.C&16777216||$CLJS.Bc===a.qf||(a.C?0:$CLJS.$a($CLJS.Wb,a)):$CLJS.$a($CLJS.Wb,a))?$CLJS.Aj:ou};nu["null"]=function(a,b){return gu(a,b)};nu.string=function(a,b){return gu(a,b)};nu.number=function(a,b){return gu(a,b)};nu.array=function(a,b){return ju(a,b)};
nu["function"]=function(a,b){return gu(a,b)};nu["boolean"]=function(a,b){return gu(a,b)};nu._=function(a,b){var c=function(){var d=mu(a);d=d instanceof $CLJS.M?d.T:null;switch(d){case "atom":return gu;case "set":return Aba;case "sequential":return ju;case "map":return iu;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}();return c.g?c.g(a,b):c.call(null,a,b)};