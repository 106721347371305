var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.WJ=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.XJ=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.YJ=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var ZJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ds,$CLJS.xs],null)),$J=null,aK=0,bK=0;;)if(bK<aK){var $ga=$J.X(null,bK);$CLJS.EF($ga,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null)],null)]));bK+=1}else{var cK=$CLJS.y(ZJ);if(cK){var dK=cK;if($CLJS.Ad(dK)){var eK=$CLJS.lc(dK),aha=$CLJS.mc(dK),
bha=eK,cha=$CLJS.D(eK);ZJ=aha;$J=bha;aK=cha}else{var dha=$CLJS.A(dK);$CLJS.EF(dha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null)],null)]));ZJ=$CLJS.B(dK);$J=null;aK=0}bK=0}else break}$CLJS.CF($CLJS.zs,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)]));
for(var fK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.EG],null)),gK=null,hK=0,iK=0;;)if(iK<hK){var eha=gK.X(null,iK);$CLJS.EF(eha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)],null)],null)]));iK+=1}else{var jK=$CLJS.y(fK);if(jK){var kK=jK;if($CLJS.Ad(kK)){var lK=$CLJS.lc(kK),fha=$CLJS.mc(kK),
gha=lK,hha=$CLJS.D(lK);fK=fha;gK=gha;hK=hha}else{var iha=$CLJS.A(kK);$CLJS.EF(iha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)],null)],null)]));fK=$CLJS.B(kK);gK=null;hK=0}iK=0}else break}
for(var mK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xr,$CLJS.Zr,$CLJS.Tr,$CLJS.Vr],null)),nK=null,oK=0,pK=0;;)if(pK<oK){var jha=nK.X(null,pK);$CLJS.CF(jha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)]));pK+=1}else{var qK=$CLJS.y(mK);if(qK){var rK=qK;if($CLJS.Ad(rK)){var sK=$CLJS.lc(rK),kha=$CLJS.mc(rK),lha=sK,mha=$CLJS.D(sK);mK=kha;nK=lha;oK=mha}else{var nha=$CLJS.A(rK);$CLJS.CF(nha,$CLJS.H([$CLJS.ot,$CLJS.Lj,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)]));mK=$CLJS.B(rK);nK=null;oK=0}pK=0}else break}$CLJS.CF($CLJS.QF,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)]));
$CLJS.CF($CLJS.WF,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)]));
for(var tK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PF,$CLJS.$F],null)),uK=null,vK=0,wK=0;;)if(wK<vK){var oha=uK.X(null,wK);$CLJS.CF(oha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)]));wK+=1}else{var xK=$CLJS.y(tK);if(xK){var yK=xK;if($CLJS.Ad(yK)){var zK=$CLJS.lc(yK),pha=$CLJS.mc(yK),qha=zK,rha=$CLJS.D(zK);tK=pha;uK=qha;vK=rha}else{var sha=$CLJS.A(yK);$CLJS.CF(sha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)]));tK=$CLJS.B(yK);
uK=null;vK=0}wK=0}else break}
for(var AK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,$CLJS.YF],null)),BK=null,CK=0,DK=0;;)if(DK<CK){var tha=BK.X(null,DK);$CLJS.CF(tha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)]));DK+=1}else{var EK=$CLJS.y(AK);if(EK){var FK=EK;if($CLJS.Ad(FK)){var GK=$CLJS.lc(FK),uha=$CLJS.mc(FK),vha=GK,wha=$CLJS.D(GK);AK=uha;BK=vha;CK=wha}else{var xha=$CLJS.A(FK);$CLJS.CF(xha,$CLJS.H([$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)]));AK=$CLJS.B(FK);
BK=null;CK=0}DK=0}else break}
for(var HK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null)],null),IK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.eG,$CLJS.yG,$CLJS.mG,$CLJS.fG],null)),JK=null,KK=0,LK=0;;)if(LK<KK){var MK=JK.X(null,LK);$CLJS.NG.v(MK,$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,MK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,$CLJS.CE,HK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.lF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)],null));LK+=1}else{var NK=$CLJS.y(IK);if(NK){var OK=NK;if($CLJS.Ad(OK)){var PK=$CLJS.lc(OK),yha=$CLJS.mc(OK),zha=PK,Aha=$CLJS.D(PK);IK=yha;JK=zha;KK=Aha}else{var QK=$CLJS.A(OK);$CLJS.NG.v(QK,$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,QK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,$CLJS.CE,HK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.lF],null)],null));IK=$CLJS.B(OK);JK=null;KK=0}LK=0}else break}
$CLJS.NG.v($CLJS.zG,$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.zG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,$CLJS.CE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,$CLJS.Yv,$CLJS.$x,$CLJS.VF],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hH],null)],null));$CLJS.NG.v($CLJS.MF,$CLJS.ot,$CLJS.Lj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.MF],null),$CLJS.CE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.pE,$CLJS.xE],null)],null));
$CLJS.X($CLJS.WJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.XJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.us,$CLJS.$r,$CLJS.EG,$CLJS.WF,$CLJS.QF,$CLJS.Xr,$CLJS.Tr,$CLJS.Zr,$CLJS.Vr,$CLJS.PF,$CLJS.$F,$CLJS.RF,$CLJS.YF,$CLJS.mG,$CLJS.fG,$CLJS.eG,$CLJS.yG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,$CLJS.Yi],null)],null));