var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var o4,q4,r4,s4,Opa;o4=function(a,b,c){var d=$CLJS.MW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.cG);d=$CLJS.zH($CLJS.Pk.j($CLJS.ch([c]),$CLJS.EE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.hi($CLJS.nE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.Ls,c,$CLJS.KF,a,$CLJS.JL,b],null));return d};$CLJS.p4=function(a){return $CLJS.i2($CLJS.AG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
q4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);r4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);s4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Opa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.L0.m(null,$CLJS.cG,function(a,b){var c=$CLJS.Fe($CLJS.cG.h($CLJS.MW(a,b)));return $CLJS.n(c)?$CLJS.k2($CLJS.PE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.i0.v(a,b,v,$CLJS.j0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.i0.v(a,
b,t,$CLJS.j0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.O0.m(null,$CLJS.cG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.wB);e=$CLJS.J.g(e,$CLJS.Fi);c=$CLJS.I(c,2,null);c=o4(a,b,c);return $CLJS.gl.l($CLJS.H([$CLJS.h0.j(a,b,c),new $CLJS.h(null,2,[$CLJS.UK,$CLJS.mL,$CLJS.l1,$CLJS.EE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.wB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Fi,e],null):null]))});
$CLJS.I0.m(null,$CLJS.cG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.i0.v(a,b,o4(a,b,c),d)});$CLJS.vF(r4,s4);
for(var t4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wx,$CLJS.oG],null)),u4=null,v4=0,w4=0;;)if(w4<v4){var Ppa=u4.X(null,w4);$CLJS.vF(Ppa,r4);w4+=1}else{var x4=$CLJS.y(t4);if(x4){var y4=x4;if($CLJS.Ad(y4)){var z4=$CLJS.lc(y4),Qpa=$CLJS.mc(y4),Rpa=z4,Spa=$CLJS.D(z4);t4=Qpa;u4=Rpa;v4=Spa}else{var Tpa=$CLJS.A(y4);$CLJS.vF(Tpa,r4);t4=$CLJS.B(y4);u4=null;v4=0}w4=0}else break}
$CLJS.I0.m(null,r4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.i0.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.nE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.nE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.PE("Count");case "cum-count":return $CLJS.PE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.J0.m(null,r4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.O0.m(null,r4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.sI($CLJS.O0,s4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Vj,$CLJS.YC)});$CLJS.I0.m(null,$CLJS.XF,function(){return $CLJS.PE("Case")});
$CLJS.J0.m(null,$CLJS.XF,function(){return"case"});$CLJS.vF(q4,s4);for(var A4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.hG,$CLJS.JG,$CLJS.TF,$CLJS.jk,$CLJS.BG,$CLJS.Zn,$CLJS.CG,$CLJS.AG,$CLJS.UF],null)),B4=null,C4=0,D4=0;;)if(D4<C4){var Upa=B4.X(null,D4);$CLJS.vF(Upa,q4);D4+=1}else{var E4=$CLJS.y(A4);if(E4){var F4=E4;if($CLJS.Ad(F4)){var G4=$CLJS.lc(F4),Vpa=$CLJS.mc(F4),Wpa=G4,Xpa=$CLJS.D(G4);A4=Vpa;B4=Wpa;C4=Xpa}else{var Ypa=$CLJS.A(F4);$CLJS.vF(Ypa,q4);A4=$CLJS.B(F4);B4=null;C4=0}D4=0}else break}
$CLJS.J0.m(null,q4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.I0.m(null,q4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.i0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.nE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.nE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.nE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.nE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.nE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.nE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.nE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.nE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.nE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.I0.m(null,$CLJS.ZF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.nE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.i0.v(a,b,e,d)]))});$CLJS.J0.m(null,$CLJS.ZF,function(){return"percentile"});
$CLJS.vF($CLJS.ZF,s4);$CLJS.I0.m(null,$CLJS.HG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.nE("Sum of {0} matching condition",$CLJS.H([$CLJS.i0.v(a,b,e,d)]))});$CLJS.J0.m(null,$CLJS.HG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.K0.j(a,b,d))].join("")});$CLJS.vF($CLJS.HG,s4);$CLJS.I0.m(null,$CLJS.uG,function(){return $CLJS.PE("Share of rows matching condition")});
$CLJS.J0.m(null,$CLJS.uG,function(){return"share"});$CLJS.vF($CLJS.uG,s4);$CLJS.I0.m(null,$CLJS.wG,function(){return $CLJS.PE("Count of rows matching condition")});$CLJS.J0.m(null,$CLJS.wG,function(){return"count-where"});$CLJS.vF($CLJS.wG,s4);
$CLJS.O0.m(null,s4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.gl.l;d=$CLJS.n(d)?$CLJS.em($CLJS.h0.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.fL],null)):null;var f=$CLJS.sI($CLJS.O0,$CLJS.ci);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.gl,$CLJS.H([d,a]))});
$CLJS.Zpa=function(){function a(d){return $CLJS.i2($CLJS.Wx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.i2($CLJS.Wx,$CLJS.Cf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.vW.m(null,$CLJS.cG,function(a){return a});
$CLJS.H4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.iC(f),$CLJS.YK))f=$CLJS.xW(f);else return $CLJS.l2(d,e,$CLJS.cG,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.I4=function(){function a(d,e){return $CLJS.Fe($CLJS.cG.h($CLJS.MW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.J4=function(){function a(d,e){var f=$CLJS.Fe($CLJS.cG.h($CLJS.MW(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(k){var l=$CLJS.h0.j(d,e,k),m=$CLJS.R.l,t=$CLJS.wB.h(l);return m.call($CLJS.R,$CLJS.h2(l,$CLJS.Fi,$CLJS.n(t)?t:$CLJS.Bj),$CLJS.UK,$CLJS.mL,$CLJS.H([$CLJS.l1,$CLJS.EE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.I0.m(null,$CLJS.WG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.SG);return $CLJS.sE.h(a.o?a.o():a.call(null))});$CLJS.P0.m(null,$CLJS.WG,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.gG);b=$CLJS.J.g(d,$CLJS.SG);c=$CLJS.J.g(d,$CLJS.QG);d=$CLJS.J.g(d,$CLJS.UZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.G0,$CLJS.bB(a),$CLJS.H([$CLJS.E0,c]));return null!=d?$CLJS.R.j(a,$CLJS.q0,d):a});
$CLJS.$pa=function(){function a(d,e){var f=function(){var m=$CLJS.kA.h($CLJS.C1($CLJS.b0(d)));return $CLJS.n(m)?m:$CLJS.bh}(),k=$CLJS.MW(d,e),l=$CLJS.T0.j(d,e,k);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Pk.j($CLJS.kf(function(m){m=$CLJS.UG.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Xl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.QG),u=$CLJS.J.g(m,$CLJS.OG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.li))return $CLJS.R.j(m,$CLJS.JW,l);t=$CLJS.Fe($CLJS.g1(function(v){return $CLJS.E_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.JW,t):null}),$CLJS.hf.h(function(m){return $CLJS.R.j(m,$CLJS.cu,$CLJS.WG)})),$CLJS.XG))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.aqa=function(){function a(d,e){return $CLJS.wW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG.h(d),$CLJS.N,$CLJS.yW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.QG.h(d)))return $CLJS.wW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG.h(d),$CLJS.N],null));var e=$CLJS.gG.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.hi(e,new $CLJS.h(null,1,[Opa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();