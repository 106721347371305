var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var TI,VI,XI,iJ,mJ,uJ;$CLJS.SI=new $CLJS.M(null,"get-month","get-month",-369374731);TI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.UI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);VI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.WI=new $CLJS.M(null,"iso","iso",-1366207543);XI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.YI=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.ZI=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.$I=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.aJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.bJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.cJ=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.dJ=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.eJ=new $CLJS.M(null,"us","us",746429226);$CLJS.fJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.gJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.hJ=new $CLJS.M(null,"now","now",-1650525531);iJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.jJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.kJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.lJ=new $CLJS.M(null,"get-week","get-week",-888059349);
mJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.nJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.oJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.pJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.qJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.rJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.sJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.tJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);uJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.vJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.wJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.xJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.yJ=new $CLJS.M(null,"target","target",253001721);$CLJS.CF($CLJS.dJ,$CLJS.H([$CLJS.ot,$CLJS.hD,$CLJS.Is,$CLJS.hH]));$CLJS.RE.m(null,$CLJS.aJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.SE(a);return $CLJS.vd(a)?(a=$CLJS.lu.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.XC,null,$CLJS.wD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.vF($CLJS.aJ,$CLJS.hF);
for(var zJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nJ,$CLJS.tJ],null)),AJ=null,BJ=0,CJ=0;;)if(CJ<BJ){var DJ=AJ.X(null,CJ);$CLJS.CF(DJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null),$CLJS.Is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hH],null)]));$CLJS.vF(DJ,$CLJS.aJ);CJ+=1}else{var EJ=$CLJS.y(zJ);if(EJ){var FJ=EJ;if($CLJS.Ad(FJ)){var GJ=$CLJS.lc(FJ),Nga=$CLJS.mc(FJ),Oga=GJ,Pga=$CLJS.D(GJ);zJ=Nga;AJ=Oga;BJ=Pga}else{var HJ=$CLJS.A(FJ);$CLJS.CF(HJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.$E],null),$CLJS.Is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hH],null)]));$CLJS.vF(HJ,$CLJS.aJ);zJ=$CLJS.B(FJ);AJ=null;BJ=0}CJ=0}else break}
for(var IJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ZI,$CLJS.SI,$CLJS.kJ,$CLJS.sJ,$CLJS.oJ,$CLJS.YI,$CLJS.vJ],null)),JJ=null,KJ=0,LJ=0;;)if(LJ<KJ){var Qga=JJ.X(null,LJ);$CLJS.CF(Qga,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null)]));LJ+=1}else{var MJ=$CLJS.y(IJ);if(MJ){var NJ=MJ;if($CLJS.Ad(NJ)){var OJ=$CLJS.lc(NJ),Rga=$CLJS.mc(NJ),Sga=OJ,Tga=$CLJS.D(OJ);IJ=Rga;JJ=Sga;KJ=Tga}else{var Uga=$CLJS.A(NJ);$CLJS.CF(Uga,$CLJS.H([$CLJS.ot,
$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null)]));IJ=$CLJS.B(NJ);JJ=null;KJ=0}LJ=0}else break}$CLJS.CF($CLJS.cJ,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eH],null)]));
for(var PJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.vJ,null,$CLJS.oJ,null,$CLJS.sJ,null,$CLJS.pJ,null,$CLJS.YI,null,$CLJS.SI,null,$CLJS.ZI,null,$CLJS.kJ,null],null),null)),QJ=null,RJ=0,SJ=0;;)if(SJ<RJ){var Vga=QJ.X(null,SJ);$CLJS.CF(Vga,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null)]));SJ+=1}else{var TJ=$CLJS.y(PJ);if(TJ){var UJ=TJ;if($CLJS.Ad(UJ)){var VJ=$CLJS.lc(UJ),Wga=$CLJS.mc(UJ),Xga=VJ,Yga=$CLJS.D(VJ);
PJ=Wga;QJ=Xga;RJ=Yga}else{var Zga=$CLJS.A(UJ);$CLJS.CF(Zga,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null)]));PJ=$CLJS.B(UJ);QJ=null;RJ=0}SJ=0}else break}$CLJS.X(uJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,$CLJS.WI,$CLJS.eJ,$CLJS.jJ],null));
$CLJS.EF($CLJS.lJ,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.qJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.xE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,2,[$CLJS.vt,"valid timezone ID",$CLJS.xt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.ZG($CLJS.Jd,$CLJS.RI.tz.names())),$CLJS.II],null)],null));
$CLJS.EF($CLJS.UI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qJ],null)],null)],null)],
null)]));$CLJS.vF($CLJS.UI,$CLJS.aJ);$CLJS.CF($CLJS.hJ,$CLJS.H([$CLJS.ot,$CLJS.$C]));$CLJS.X(XI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.wC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XC,$CLJS.wD],null))}],null)],null));
$CLJS.X(TI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wB,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XI],null)],null)],null)],null));
$CLJS.NG.g($CLJS.$I,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.vt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.$I],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.MI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.Yv],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bH],null)],null)],null)],null)],null)],null));
$CLJS.RE.m(null,$CLJS.$I,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Yv)?$CLJS.E.g(a,$CLJS.ci)?$CLJS.wD:$CLJS.n($CLJS.uF($CLJS.gH,a))?$CLJS.XC:$CLJS.wD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.DI,b))?$CLJS.XC:$CLJS.n($CLJS.nh($CLJS.EI,b))?$CLJS.XC:null:null;if($CLJS.n(a))return a;b=$CLJS.SE(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.wC(c,$CLJS.Wj)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(mJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.Yv],null),$CLJS.Is],null));
$CLJS.EF($CLJS.fJ,$CLJS.H([$CLJS.ot,$CLJS.wD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hH],null)],null)],null)],null)]));
$CLJS.CF($CLJS.Ky,$CLJS.H([$CLJS.ot,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fH],null)]));$CLJS.X(VI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.us,$CLJS.dH,$CLJS.Ai,$CLJS.mk,$CLJS.bJ,$CLJS.wJ,$CLJS.xJ,$CLJS.Gj,$CLJS.Gi,$CLJS.wi,$CLJS.jj,$CLJS.aH],null));$CLJS.X(iJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,$CLJS.WI,$CLJS.eJ,$CLJS.jJ],null));
$CLJS.EF($CLJS.rJ,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iJ],null)],null)],
null)],null)]));