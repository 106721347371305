var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var AH,BH,DH,EH,Jfa,Kfa,Lfa,FH,CH;$CLJS.yH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.zH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
AH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.wC(b,$CLJS.XC)?$CLJS.jH:$CLJS.wC(b,$CLJS.WD)?$CLJS.fH:$CLJS.wC(b,$CLJS.wD)?$CLJS.hH:null;return $CLJS.n(b)?$CLJS.uF(b,a):!0};
BH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.yH(function(d){return $CLJS.wC($CLJS.SE(d),$CLJS.hD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Nk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.SE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(AH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
DH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.vt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Ej);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(BH(b))].join("")}],null),$CLJS.Te(BH)],null)],null)};
EH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.vt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)],null)],null)};
Jfa=function(a){return $CLJS.Qd($CLJS.xF,$CLJS.hf.g(function(b){var c=$CLJS.SE(b),d=$CLJS.wC(c,$CLJS.dF);b=d?$CLJS.uF($CLJS.rG,b):d;return $CLJS.n(b)?$CLJS.Pj:c},a))};Kfa=function(a){a=$CLJS.zH(function(b){return!$CLJS.wC(b,$CLJS.hD)},$CLJS.hf.g($CLJS.SE,a));return $CLJS.wC(a,$CLJS.dF)?$CLJS.Wj:a};
Lfa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.wC($CLJS.SE(c),$CLJS.hD)},b))?Kfa(b):$CLJS.E.g(a,$CLJS.ot)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.wC($CLJS.SE(c),$CLJS.XC)},b)||$CLJS.Qe(function(c){return $CLJS.wC($CLJS.SE(c),$CLJS.wD)},b))?$CLJS.hD:Jfa(b)};FH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.GH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);CH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,CH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.SE(a);return $CLJS.Qe(function(d){return AH(d,c)},b)}],null)],null));
$CLJS.X(FH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)],null));
var Mfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.vt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.ot],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null)],
null);$CLJS.NG.g($CLJS.bs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,DH($CLJS.bs),EH($CLJS.bs)],null));$CLJS.NG.g($CLJS.ot,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,DH($CLJS.ot),Mfa,EH($CLJS.ot)],null));$CLJS.EF($CLJS.cs,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,FH],null)]));$CLJS.EF($CLJS.IG,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,FH],null)]));
for(var HH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,$CLJS.ot,$CLJS.cs],null)),IH=null,JH=0,KH=0;;)if(KH<JH){var Nfa=IH.X(null,KH);$CLJS.vF(Nfa,$CLJS.GH);KH+=1}else{var LH=$CLJS.y(HH);if(LH){var MH=LH;if($CLJS.Ad(MH)){var NH=$CLJS.lc(MH),Ofa=$CLJS.mc(MH),Pfa=NH,Qfa=$CLJS.D(NH);HH=Ofa;IH=Pfa;JH=Qfa}else{var Rfa=$CLJS.A(MH);$CLJS.vF(Rfa,$CLJS.GH);HH=$CLJS.B(MH);IH=null;JH=0}KH=0}else break}
$CLJS.RE.m(null,$CLJS.GH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Lfa(a,b)});$CLJS.CF($CLJS.OF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.vF($CLJS.OF,$CLJS.hF);
for(var OH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KG,$CLJS.FF,$CLJS.aG],null)),PH=null,QH=0,RH=0;;)if(RH<QH){var Sfa=PH.X(null,RH);$CLJS.CF(Sfa,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));RH+=1}else{var SH=$CLJS.y(OH);if(SH){var TH=SH;if($CLJS.Ad(TH)){var UH=$CLJS.lc(TH),Tfa=$CLJS.mc(TH),Ufa=UH,Vfa=$CLJS.D(UH);OH=Tfa;PH=Ufa;QH=Vfa}else{var Wfa=$CLJS.A(TH);$CLJS.CF(Wfa,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));OH=$CLJS.B(TH);PH=null;QH=0}RH=0}else break}
for(var VH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,$CLJS.lG,$CLJS.sG],null)),WH=null,XH=0,YH=0;;)if(YH<XH){var Xfa=WH.X(null,YH);$CLJS.CF(Xfa,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));YH+=1}else{var ZH=$CLJS.y(VH);if(ZH){var $H=ZH;if($CLJS.Ad($H)){var aI=$CLJS.lc($H),Yfa=$CLJS.mc($H),Zfa=aI,$fa=$CLJS.D(aI);VH=Yfa;WH=Zfa;XH=$fa}else{var aga=$CLJS.A($H);$CLJS.CF(aga,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));VH=$CLJS.B($H);WH=null;XH=0}YH=0}else break}$CLJS.CF($CLJS.LF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));
$CLJS.RE.m(null,$CLJS.LF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.wC($CLJS.SE(b),$CLJS.xj)&&$CLJS.wC($CLJS.SE(a),$CLJS.xj)?$CLJS.xj:$CLJS.TD});